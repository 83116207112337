import type { Dayjs } from "dayjs";
import { UseFormReturn } from "react-hook-form";
import { Job } from "./Job.interface";

export interface Technology {
  id: number;
  category: string;
  technology: string;
}
export interface Status {
  id: number;
  status: string;
  type: string;
}

export interface SelectedTechnology {
  technology: string;
  experience_in_years: number;
}
export interface SelectedLanguage {
  language: string;
  level: string;
}

export interface Education {
  name: string;
  degree: string;
  institution: string;
  from: Dayjs;
  to: Dayjs;
  ongoing: boolean;
}
export interface Project {
  company_name: string;
  project_name: string;
  project_role: string;
  project_description: string;
  project_responsibilities: string;
  from: Dayjs;
  to: Dayjs;
  ongoing: boolean;
  technologies: string[];
}

export interface TalentJob {
  id: number;
  job_id: number;
  talent_id: number;
  created_at: string;
  job: Job;
  talent: Talent;
  status: string;
}

export interface SalaryRange {
  from: string;
  to: string;
  currency: string;
}

export interface Talent {
  [key: string]: any;
  id: number | null;
  name: string;
  email: string;
  phone: string;
  country: string;
  english_level: string;
  cv_short_description: string;
  linked_in_url: string;
  type: string;
  seniority: string;
  work_type: string;
  salary: SalaryRange | null;
  score: Score;
  cv_experience: number;
  cv_detailed_description: string;
  cv_core_technologies: SelectedTechnology[] | [];
  cv_other_technologies: SelectedTechnology[] | [];
  cv_languages: SelectedLanguage[] | [];
  cv_projects: Project[] | [];
  cv_file: File | null;
  status: Status | null;
  comment: string;
  jobs: TalentJob[];
  created_at: string;
  updated_at: string;
}

export type EmptyTalent = Omit<Talent, "id" | "created_at" | "updated_at">;

export interface TalentHistoryType {
  id: number;
  talent_id: number;
  history_type: string;
  comment: string;
  created_by: { username: string; role_id: number };
  created_at: string;
}

export interface TalentStatusHistoryType {
  id: number;
  old_status: Status;
  new_status: Status;
  created_by: { username: string; role_id: number };
  created_at: string;
}

export interface TalentPublicType {
  [key: string]: any;
  id: number | null;
  name: string;
  email: string;
  phone: string;
  country: string;
  english_level: string;
  cv_short_description: string;
  linked_in_url: string;
  seniority: string;
  cv_experience: number;
  cv_detailed_description: string;
  cv_core_technologies: SelectedTechnology[] | [];
  cv_languages: SelectedLanguage[] | [];
  cv_projects: Project[] | [];
}

export type TalentStatus = "view" | "pending" | "error" | "edit";

export interface ITalentState {
  talent: Talent | EmptyTalent | null;
  originalTalent: Talent | EmptyTalent | null;
  status: TalentStatus;
  form: UseFormReturn<any> | null;
}

export interface ITalentUpdatePayload {
  [key: string]: any;
  name?: string;
  email?: string;
  phone?: string;
  country?: string;
  english_level?: string;
  cv_short_description?: string;
  linked_in_url?: string;
  type?: string;
  seniority?: string;
  cv_experience?: number;
  cv_detailed_description?: string;
  cv_core_technologies?: SelectedTechnology[] | [];
  cv_other_technologies?: SelectedTechnology[] | [];
  cv_languages?: SelectedLanguage[] | [];
  cv_projects?: Project[] | [];
  cv_file?: File | null;
  status_id?: number | null;
  comment?: string;
  created_at?: string;
  updated_at?: string;
}

export enum ScoreCategory {
  CommunicationSkills = "communication_skills",
  ProblemSolving = "problem_solving",
  AdaptabilityAndFlexibility = "adaptability_and_flexibility",
  Interview = "interview",
  Technical = "technical",
  English = "english",
}

export type Score = Record<ScoreCategory, number> & {
  total: number;
};

export type TechnologyFormType = {
  technology: string;
  experience: number;
  index: number;
  form: "core" | "other";
  handleTechnologyDelete: (e: any, index: number, form: string) => void;
};

export type LanguageFormType = {
  language: string;
  level: string;
  index: number;
  handleLanguageDelete: (e: any, index: number) => void;
};

export interface TalentEvent {
  talent_id: number;
  activity: unknown;
  created_by: string;
  created_at: string;
}

export interface CommentEvent extends TalentEvent {
  type: "comment_activity";
  activity: { comment: string };
}

export interface StatusChangeEvent extends TalentEvent {
  type: "status_activity";
  activity: { old_status: number; new_status: number };
}

export interface TalentJobInsertEvent extends TalentEvent {
  type: "talents_jobs_insert_activity";
  activity: { job_id: number; status: string };
}

export interface TalentJobUpdateEvent extends TalentEvent {
  type: "talents_jobs_update_activity";
  activity: { job_id: number; old_status: string; new_status: string };
}

export interface SalaryChangeEvent extends TalentEvent {
  type: "talents_salary_update_activity";
  activity: {
    oldSalary: {
      salaryFrom: string;
      salaryTo: string;
      salaryCurrency: string;
    };
    newSalary: {
      salaryFrom: string;
      salaryTo: string;
      salaryCurrency: string;
    };
  };
}

export type TalentActivity =
  | CommentEvent
  | StatusChangeEvent
  | TalentJobInsertEvent
  | TalentJobUpdateEvent
  | SalaryChangeEvent;
