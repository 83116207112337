import { TalentProfile, Talents } from "@pages/talents";
import {
  FilterContextProvider,
  TalentsStoreProvider,
  TalentStoreProvider,
} from "@store";
import { Route, Routes } from "react-router";

const TalentsRouter = () => {
  return (
    <FilterContextProvider>
      <TalentsStoreProvider>
        <TalentStoreProvider>
          <Routes>
            <Route path=":id" element={<TalentProfile />} />
            <Route path="add" element={<TalentProfile addMode={true} />} />
          </Routes>
        </TalentStoreProvider>
        <Routes>
          <Route path="/" element={<Talents />} />
        </Routes>
      </TalentsStoreProvider>
    </FilterContextProvider>
  );
};
export { TalentsRouter };
