import { Autocomplete, TextField } from "@mui/material";
import { useTalentsStore, useTalentStore } from "@store";
import { useEffect, useRef } from "react";

export const TalentStatus = (props: any) => {
  const { status } = useTalentsStore();
  const { state, dispatch } = useTalentStore();
  const submitChanges = props.submitChanges;
  const firstRender = useRef(true);
  const logActivity = props.logActivity;

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    } else {
      submitChanges();
    }
  }, [state.talent?.status_id]);

  return (
    <Autocomplete
      size="small"
      disabled={state.status === "edit"}
      options={status}
      groupBy={(option: any) => option?.type}
      getOptionLabel={(option: any) => option?.status}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Status"
          variant="outlined"
          disabled={state.status === "edit"}
        />
      )}
      onChange={(e: any, value: any) => {
        if (state.talent?.status_id !== value?.id)
          logActivity("status_activity", {
            old_status: state.talent?.status_id,
            new_status: value?.id,
          });
        dispatch({
          type: "SET_TALENT",
          payload: { ...state.talent, status_id: value?.id },
        });
      }}
      value={status.find((s) => s.id === state.talent?.status_id) || null}
      isOptionEqualToValue={(option: any, newValue: any) => {
        return option.id === newValue.id;
      }}
    />
  );
};
