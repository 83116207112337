import { TextLabel } from "@components/textlabel";
import { Client } from "@interfaces";
import { Autocomplete, TextField } from "@mui/material";
import { useJobStore } from "@store";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router";

const JobClientInput = (props: any) => {
  const { jobState, jobDispatch } = useJobStore();
  const [clients, setClients] = useState<Client[]>([]);

  const {
    register,
    formState: { errors },
  } = jobState.form!;

  useEffect(() => {
    if (props.clientId) {
      jobDispatch({
        type: "SET_JOB",
        payload: {
          ...jobState.job,
          client_id: props.clientId,
        },
      });
    }
  }, []);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/clients`).then((res) => {
      setClients(res.data);
    });
  }, []);

  return (
    <div className="w-100">
      <TextLabel>Client</TextLabel>
      <div className="d-flex flex-row flex-nowrap job-client">
        {jobState.status === "edit" ? (
          <Autocomplete
            fullWidth
            getOptionLabel={(option: Client) => option.name}
            options={clients}
            size="small"
            disabled={Boolean(props.clientId)}
            value={
              clients?.find((c: Client) => c.id === jobState.job?.client_id) ||
              null
            }
            onChange={(e, value) =>
              jobDispatch({
                type: "SET_JOB",
                payload: {
                  ...jobState.job,
                  client_id: value?.id || null,
                  client: value || null,
                },
              })
            }
            renderInput={(params) => <TextField {...params} />}
          />
        ) : (
          <>
            {" "}
            <Link
              to={`/clients/${
                jobState.job?.client.id || jobState.job?.client_id
              }`}
              className="d-flex gap-1 py-1 align-items-center"
            >
              {jobState.job?.client?.name || ""}
            </Link>
          </>
        )}
      </div>
    </div>
  );
};

export { JobClientInput };
