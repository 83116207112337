import { TextLabel } from "@components/textlabel";
import { useJobStore } from "@store";
import { stripHtml, wrapLinksInAnchorTags } from "@utils";
import parse from "html-react-parser";
import { useEffect } from "react";
import ReactQuill from "react-quill-new";

type JobTextFieldProps = {
  label: string;
  field: string;
  link?: boolean;
  required?: boolean;
  internal?: boolean;
};

const JobRTE = ({
  field,
  link,
  label,
  required,
  internal,
}: JobTextFieldProps) => {
  const { jobState: state, jobDispatch: dispatch } = useJobStore();
  const fieldValue = state.job?.[field];
  const {
    register,
    setValue,
    formState: { errors },
  } = state.form!;

  useEffect(() => {
    register(field, { required: required });
  }, []);

  const onEditorStateChange = (value: string) => {
    setValue(field, stripHtml(value) || null);

    dispatch({
      type: "SET_JOB",
      payload: {
        ...state.job,
        [field]: value,
      },
    });
  };

  if (state.status !== "edit" && !stripHtml(fieldValue)) return null;

  return (
    <div className="w-100">
      <TextLabel internal={internal}>{label}</TextLabel>
      {state.status === "edit" ? (
        <div className="d-flex flex-column">
          <ReactQuill
            className={errors[field] && "error-input"}
            theme="snow"
            value={fieldValue || ""}
            onChange={onEditorStateChange}
          />
          {errors[field] && <p className="error">{"Enter valid content"}</p>}
        </div>
      ) : (
        <div className="py-1">
          {link ? parse(wrapLinksInAnchorTags(fieldValue)) : parse(fieldValue)}
        </div>
      )}
    </div>
  );
};

export { JobRTE };
