export const TalentScorecardTooltip = () => {
  return (
    <div className="p-2" style={{ fontSize: "15px" }}>
      <div
        className="d-flex flex-column gap-2 mb-2"
        style={{ fontSize: "16px" }}
      >
        <p>Communication skills - 10%</p> <p>Problem-Solving - 10%</p>{" "}
        <p>Adaptability & Flexibility - 15%</p>{" "}
        <p>Interview (HR + Cultural) - 25%</p> <p>Technical - 30%</p>
        <p>English - 10%</p>
      </div>

      <p>
        <p className="fs-6 my-2">
          <strong>Communication:</strong>
        </p>
        Ability to convey information, ideas, thoughts, or feelings effectively
        and clearly to others
        <ul>
          <li>
            Clarity: The ability to express thoughts and ideas clearly and
            succinctly, avoiding ambiguity and confusion.
          </li>
          <li>
            Listening: Actively listening to others, understanding their
            perspectives, and responding appropriately. Good communication
            involves a two-way exchange.
          </li>
        </ul>
      </p>
      <p>
        <p className="fs-6 my-2">
          <strong>Problem-Solving:</strong>
        </p>
        Strong analytical and problem-solving skills to debug issues and develop
        effective solutions.
        <ul>
          <li>
            Analytical Thinking: The capacity to break down complex problems
            into smaller, manageable components for analysis.
          </li>
          <li>
            Critical Thinking: Evaluating information and situations
            objectively, considering multiple perspectives and potential
            solutions.
          </li>
        </ul>
      </p>

      <p>
        <p className="fs-6 my-2">
          <strong>Adaptability:</strong>
        </p>
        Adaptability refers to a developer's capacity to adjust and thrive in
        the face of changing circumstances, whether it be shifts in project
        requirements, emerging technologies, or modifications in team
        structures. An adaptable developer welcomes change as an opportunity for
        growth and improvement rather than a hindrance. They stay receptive to
        new tools, methodologies, and industry best practices, continuously
        updating their skill set to remain effective and relevant.
      </p>

      <p>
        <p className="fs-6 my-2">
          <strong>Flexibility:</strong>
        </p>
        Flexibility complements adaptability and involves the willingness to
        alter one's approach or mindset based on the demands of the situation. A
        flexible developer can seamlessly transition between different tasks,
        project phases, or work methodologies. They are open to diverse
        perspectives and solutions, making them adept at adjusting to unexpected
        challenges and accommodating various project dynamics. Flexibility is
        also crucial in collaborative efforts, allowing developers to adapt
        their communication and teamwork styles to ensure a harmonious working
        environment.
      </p>
    </div>
  );
};
