import { Score } from "@interfaces";
import { Rating, RatingTypeMap } from "@mui/material";
import { useTalentStore } from "@store";
import { useEffect, useState } from "react";

export type TalentRatingProps = RatingTypeMap["props"] & {
  score?: Score;
  hideLabel?: boolean;
  shortLabel?: boolean;
  showCompleteOnly?: boolean;
};

export const TalentRatingTotal = ({
  score,
  hideLabel,
  shortLabel,
  showCompleteOnly,
  ...props
}: TalentRatingProps) => {
  const {
    state: { talent },
  } = useTalentStore();

  const [show, setShow] = useState<boolean>(true);

  const talentScore = score ?? talent?.score;

  useEffect(() => {
    if (!showCompleteOnly) return;

    const scoreKeys = Object.keys(talentScore || {});

    setShow(
      scoreKeys.every((key) => {
        if (talentScore?.[key] !== 0) {
          return true;
        }
        return false;
      })
    );
  }, []);

  return (
    (Number(talentScore?.total || 0) !== 0 && show && (
      <div className="row d-flex flex-row">
        <div className="align-content-center d-flex flex-row gap-2">
          <Rating
            {...props}
            max={5}
            precision={0.1}
            readOnly={true}
            sx={{
              width: "100%",
              "&.MuiRating-root": {
                width: "100%",
                display: "flex",
                justifyContent: "space-around",
              },
              "& .MuiRating-decimal >span": {
                justifyItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                display: "flex",
              },
              "& .MuiRating-icon": {
                color: "#f5a13f",
              },
            }}
            value={talentScore?.total || 0}
          ></Rating>
          {hideLabel ? null : (
            <div className="d-flex gap-1 flex-row justify-content-center flex-nowrap text-nowrap">
              <strong>{Math.round(talentScore?.total * 10) / 10}</strong>
              {shortLabel ? null : (
                <p>
                  out of <strong>5</strong>
                </p>
              )}
            </div>
          )}
        </div>
      </div>
    )) || <div></div>
  );
};
