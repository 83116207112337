import { SelectedLanguage } from "@interfaces";
import { Button } from "@mui/material";
import { useTalentStore } from "@store";
import { MDBCard, MDBCardBody, MDBCardHeader } from "mdb-react-ui-kit";
import { TalentLanguagesForm } from ".";

export const TalentLanguages = (props: any) => {
  const { state, dispatch } = useTalentStore();

  const handleLanguageDelete = (e: any, index: number) => {
    const changes = {
      cv_languages: state.talent?.cv_languages?.filter(
        (lang: any, i: number) => i !== index
      ),
    };
    dispatch({
      type: "SET_TALENT",
      payload: { ...state.talent, ...changes },
    });
  };

  if (!state.talent?.cv_languages?.length && state.status !== "edit")
    return null;
  return (
    <>
      <MDBCard>
        <MDBCardHeader>
          <div className="d-flex flex-row align-items-center gap-2">
            Languages
            <div
              style={{
                display: "inline-block",
                color: "orange",
                fontSize: 12,
                fontStyle: "italic",
                fontWeight: 300,
              }}
            >
              public
            </div>
          </div>
        </MDBCardHeader>
        <MDBCardBody className="pt-0 pb-2">
          <div className="talent-skill-list">
            {state.talent?.cv_languages.map(
              (lang: SelectedLanguage, index: number) => {
                return (
                  <div key={index}>
                    <TalentLanguagesForm
                      index={index}
                      language={lang.language}
                      level={lang.level}
                      handleLanguageDelete={handleLanguageDelete}
                    />
                  </div>
                );
              }
            )}
          </div>

          {state.status === "edit" ? (
            <div>
              <Button
                className="my-2"
                size="small"
                variant="outlined"
                style={{
                  outline: "none",
                  boxShadow: "none",
                  borderRadius: 30,
                }}
                onClick={(e) => {
                  const changes = {
                    cv_languages: [
                      ...state.talent?.cv_languages,
                      {
                        language: "",
                        level: "",
                      },
                    ],
                  };
                  dispatch({
                    type: "SET_TALENT",
                    payload: { ...state.talent, ...changes },
                  });
                }}
              >
                + Add language
              </Button>
            </div>
          ) : (
            ""
          )}
        </MDBCardBody>
      </MDBCard>
    </>
  );
};
