import { TalentCV, TalentTextField } from "@components/talents";
import { useTalentsStore } from "@store";
import { MDBCard } from "mdb-react-ui-kit";
import { forwardRef } from "react";
import SalaryRangeField from "./SalaryRangeField";

export const TalentGeneralInfo = forwardRef((props: any, ref: any) => {
  const { types, roles, seniorities, work_types } = useTalentsStore();

  return (
    <MDBCard className="general-info">
      <div className="general-info-grid">
        <TalentTextField field="name" label="Name" required />
        <TalentTextField
          required
          field="type"
          label="Type"
          select
          options={types}
        />
        <TalentTextField
          field="seniority"
          label="Seniority"
          select
          options={seniorities}
        />
        <TalentTextField field="linked_in_url" label="Profile" link />
        <TalentTextField field="role" label="Role" select options={roles} />
        <TalentTextField field="email" label="Email address" />
        <TalentTextField field="cv_experience" label="Experience (years)" />
        <TalentTextField field="phone" label="Phone number" />
        <TalentTextField
          field="work_type"
          label="Work type"
          select
          options={work_types}
        />
        <SalaryRangeField />
      </div>

      <TalentCV submitChanges={props.submitChanges} addMode={props.addMode} />
    </MDBCard>
  );
});
