import { ScoreCategory } from "@interfaces";
import { Rating, RatingTypeMap } from "@mui/material";
import { useTalentStore } from "@store";

export type TalentRatingProps = RatingTypeMap["props"] & {
  label: string;
  control: ScoreCategory;
};

export const TalentRating = ({
  label,
  control,
  ...props
}: TalentRatingProps) => {
  const { state, dispatch } = useTalentStore();

  const onChange = (e: any) => {
    const rating =
      Number(state.talent?.score?.[control]) === Number(e.target.value)
        ? 0
        : Number(e.target.value);

    dispatch({
      type: "SET_TALENT",
      payload: {
        ...state.talent,
        score: {
          ...state.talent?.score,
          [control]: rating,
        },
      },
    });
  };

  return (
    <div className="row">
      <p className="col text-wrap text-break">{label}</p>
      <div className="col-9 align-content-center">
        <Rating
          {...props}
          onChange={onChange}
          name={label}
          readOnly={state.status !== "edit"}
          max={5}
          value={state.talent?.score?.[control] || 0}
          sx={{
            width: "100%",
            "&.MuiRating-root > label:last-child": {
              display: "none",
            },
            "&.MuiRating-root > label": {
              width: "100%",
            },
            "&.MuiRating-root > span": {
              width: "100%",
            },
            "& .MuiRating-icon": {
              fontSize: "1.5rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#f5a13f",
            },
          }}
        ></Rating>
      </div>
    </div>
  );
};
