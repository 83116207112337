import { TextLabel } from "@components/textlabel";
import { useTalentStore } from "@store";
import { calcDuration } from "@utils";
import { TalentDatePicker } from ".";
import { Dayjs } from "dayjs";

export const TalentCalendarRange = (props: any) => {
  const { state, dispatch } = useTalentStore();
  const addMode = props.addMode;
  const index = props.index;
  const objectType = props.objectType;
  const dateObjects = props.dateObjects;
  const dateFrom = dateObjects[index]?.from;
  const dateTo = dateObjects[index]?.to;

  const changeDateFrom = (date: any) => {
    dispatch({
      type: "SET_TALENT",
      payload: {
        ...state.talent,
        [objectType]: dateObjects.map((object: any, i: number) =>
          i === index ? { ...object, from: date.toISOString() } : object
        ),
      },
    });
  };
  const changeDateTo = (date: Dayjs) => {
    dispatch({
      type: "SET_TALENT",
      payload: {
        ...state.talent,
        [objectType]: dateObjects.map((object: any, i: number) =>
          i === index ? { ...object, to: date.toISOString() } : object
        ),
      },
    });
  };

  return (
    <div className="d-flex flex-column">
      <TextLabel>Period</TextLabel>

      <div className="d-flex flex-row gap-2 mt-2">
        <TalentDatePicker
          addMode={addMode}
          dateFrom={dateFrom}
          editIndex={props?.editIndex}
          index={index}
          onChange={changeDateFrom}
        />
        {"-"}
        <TalentDatePicker
          addMode={addMode}
          dateTo={dateTo}
          editIndex={props?.editIndex}
          onChange={changeDateTo}
          index={index}
          ongoing={dateObjects[index]?.ongoing}
        />{" "}
        {state.status !== "edit" ? (
          <div className="font-weight-normal text-muted">
            ({calcDuration(dateFrom, dateTo, dateObjects[index]?.ongoing)})
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
