import { Filters, TalentsTable } from "@components/talents";
import { Button } from "@mui/material";
import { useFilterContext } from "@store";
import "@styles/talents.css";
import { serializeQueryParams } from "@utils";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router";

const Talents = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useFilterContext();
  const [total, setTotal] = useState(state?.items?.length || 0);

  const fetchTalents = useCallback(() => {
    const { creation_method, ...filters } = state.filters ?? {};
    const is_imported =
      typeof creation_method === "string"
        ? creation_method === "Imported"
        : undefined;

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/talents${serializeQueryParams({
          ...filters,
          is_imported,
        })}`,
      )
      .then((response) => {
        dispatch({ type: "SET_ITEMS", payload: response.data });
        dispatch({ type: "SET_STATUS", payload: "success" });
        setTotal(parseInt(response.headers["x-total-count"]!) || 0);
      })
      .catch((error) => {
        dispatch({ type: "SET_STATUS", payload: "error" });
      });
  }, [state.filters]);

  useEffect(() => {
    if (state.filters) {
      navigate("/talents" + serializeQueryParams(state.filters));
    }
    dispatch({ type: "SET_STATUS", payload: "pending" });

    fetchTalents();
  }, [fetchTalents]);

  return (
    <div className="talents-container">
      <div className="top">
        <div>
          <h3>Talents</h3>
        </div>
        <div>
          <Link to={"/talents/add"}>
            <Button className="button" size="small" variant="contained">
              Add talent
            </Button>
          </Link>
        </div>
      </div>

      <Filters />

      <TalentsTable total={total} />
    </div>
  );
};
export { Talents };
