import { useContext, useEffect } from "react";
import { UNSAFE_NavigationContext as NavigationContext } from "react-router";

const useNavigationGuard = (when: boolean) => {
  const { navigator } = useContext(NavigationContext);

  const confirm = () =>
    window.confirm("Are you sure you want to leave this page?");

  useEffect(() => {
    if (!when) {
      return;
    }

    const { go, push } = navigator;

    navigator.go = (...args) => {
      if (when && !confirm()) {
        console.log("Navigation prevented by condition");
        return;
      }
      go(...args);
    };

    navigator.push = (...args) => {
      if (when && !confirm()) {
        return;
      }
      push(...args);
    };

    return () => {
      navigator.go = go;
      navigator.push = push;
    };
  }, [when, navigator]);
};

export default useNavigationGuard;
