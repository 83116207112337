import { BEND_PRIMARY } from "@const";
import { TalentJob } from "@interfaces";
import { Button, Popper } from "@mui/material";
import { useJobStore } from "@store";
import { calculateOtherJobsCount } from "@utils";
import { MDBCard, MDBCardBody, MDBCardText } from "mdb-react-ui-kit";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router";
import { JobTalentsForm } from "./JobTalentForm";

export const JobTalentsList = (props: any) => {
  const { jobState: state, jobDispatch: dispatch } = useJobStore();
  const [popperIndexOpen, setPopperIndexOpen] = useState<number>(-1);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const popperRef = useRef<any>(null);
  const [formOpen, setFormOpen] = useState<boolean>(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    setAnchorEl(event.currentTarget);
    setPopperIndexOpen(index);
  };

  useEffect(() => {
    window.addEventListener("mousedown", (e) => {
      if (!popperRef.current?.contains(e.target) && e.target !== anchorEl) {
        setPopperIndexOpen(-1);
      }
    });
    return () => {
      window.removeEventListener("mousedown", (e) => {
        if (!popperRef.current?.contains(e.target) && e.target !== anchorEl) {
          setPopperIndexOpen(-1);
        }
      });
    };
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between  ">
        <MDBCardText>Talents</MDBCardText>
        <Button
          className="p-0"
          variant="text"
          onClick={() => setFormOpen(!formOpen)}
        >
          +add
        </Button>
      </div>
      {formOpen && (
        <JobTalentsForm fetchJob={props.fetchJob} setFormOpen={setFormOpen} />
      )}
      {state.job?.talents?.length
        ? state.job?.talents.map((talent: TalentJob, index: number) => {
            return (
              <MDBCard key={index} className="border-0">
                <MDBCardBody>
                  <div className="d-flex justify-content-between text-nowrap">
                    <Link to={`/talents/${talent.talent_id}`}>
                      <div className="d-flex align-items-center gap-2">
                        {talent.talent.name}
                      </div>
                    </Link>
                    <div>
                      <div
                        className="font-weight-bold d-inline"
                        style={{
                          color: talent.status.toLowerCase().includes("reject")
                            ? "red"
                            : talent.status.toLowerCase().includes("accept")
                            ? "green"
                            : BEND_PRIMARY,
                        }}
                      >
                        {talent.status}
                      </div>{" "}
                    </div>
                  </div>
                  {calculateOtherJobsCount(
                    talent.talent.jobs,
                    state.job?.id,
                  ) ? (
                    <div>
                      {" "}
                      <hr className="my-2" />
                      Also in{" "}
                      <Link to={""}>
                        <div
                          className="d-inline"
                          onClick={(e) => handleClick(e, index)}
                        >
                          {calculateOtherJobsCount(
                            talent.talent.jobs,
                            state.job?.id,
                          )}{" "}
                          other job(s)
                        </div>
                      </Link>
                      <Popper
                        placement="bottom-start"
                        open={popperIndexOpen === index}
                        anchorEl={anchorEl}
                      >
                        <MDBCard
                          ref={popperRef}
                          className="job-other-jobs-popper"
                        >
                          <>
                            <div>
                              {talent.talent.name} is also in the following
                              jobs:
                            </div>
                            <div className="d-flex flex-column gap-3">
                              {talent.talent.jobs.map(
                                (job: TalentJob, indexOther: number) => {
                                  if (job.job.id !== state.job?.id)
                                    return (
                                      <div key={indexOther}>
                                        <Link
                                          to={`/jobs/${job.job_id}`}
                                          replace
                                        >
                                          <h6 className="d-flex flex-row align-items-center gap-1 font-weight-bold m-0">
                                            {job.job.name}
                                          </h6>{" "}
                                        </Link>
                                        <div>
                                          {job.job.client?.name}
                                          {" | "}
                                          {job.job.client?.country}
                                        </div>
                                        <div
                                          className="font-weight-bold d-inline"
                                          style={{
                                            color: job.status
                                              .toLowerCase()
                                              .includes("reject")
                                              ? "red"
                                              : job.status
                                                  .toLowerCase()
                                                  .includes("accept")
                                              ? "green"
                                              : BEND_PRIMARY,
                                          }}
                                        >
                                          {job.status}
                                        </div>{" "}
                                      </div>
                                    );
                                },
                              )}
                            </div>
                          </>
                        </MDBCard>
                      </Popper>
                    </div>
                  ) : (
                    ""
                  )}
                </MDBCardBody>
              </MDBCard>
            );
          })
        : ""}
    </>
  );
};
