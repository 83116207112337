import { TextLabel } from "@components/textlabel";
import { MenuItem, TextField } from "@mui/material";
import { useTalentStore } from "@store";
import { wrapLinksInAnchorTags } from "@utils";
import parse from "html-react-parser";

type TalentTextFieldProps = {
  label: string;
  field: string;
  select?: boolean;
  link?: boolean;
  options?: any[];
  required?: boolean;
  internal?: boolean;
};

export const TalentTextField = ({
  field,
  select,
  link,
  options,
  label,
  required,
  internal,
}: TalentTextFieldProps) => {
  const { state, dispatch } = useTalentStore();
  const fieldValue = state.talent?.[field];
  const {
    register,
    formState: { errors },
  } = state.form!;

  if (state.status !== "edit" && !state.talent?.[field]) return null;

  const renderSelectMenu = () => {
    if (select) {
      const items = options?.map((option: any, index: number) => {
        return (
          <MenuItem className="text-capitalize" key={index} value={option}>
            {option}
          </MenuItem>
        );
      });
      return [
        <MenuItem
          key={-1}
          className="text-capitalize"
          disabled
          hidden
          value={"null"}
        ></MenuItem>,
        ...(items || []),
      ];
    }
    return null;
  };

  return (
    <div>
      <TextLabel internal={internal}>{label}</TextLabel>
      {state.status === "edit" ? (
        <TextField
          {...register(field, { required })}
          error={!!errors[field]}
          helperText={errors[field] ? "Required" : ""}
          style={{ height: "32px" }}
          className="w-100"
          select={select}
          size="small"
          variant="outlined"
          value={state.talent?.[field] || ""}
          onChange={(event: any) => {
            dispatch({
              type: "SET_TALENT",
              payload: {
                ...state.talent,
                [field]: event.target.value,
              },
            });
          }}
        >
          {renderSelectMenu()}
        </TextField>
      ) : (
        <div className="py-1">
          {link ? parse(wrapLinksInAnchorTags(fieldValue)) : fieldValue}
        </div>
      )}
    </div>
  );
};
