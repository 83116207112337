import { JobsTable } from "@components/jobs";
import { Job } from "@interfaces";
import { Button } from "@mui/material";
import "@styles/jobs.css";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router";

const Jobs = () => {
  const [jobs, setJobs] = useState<Job[]>([]);
  const navigate = useNavigate();

  const fetchJobs = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/jobs`).then((response) => {
      setJobs(response.data);
    });
  };

  useEffect(() => {
    fetchJobs();
  }, []);

  return (
    <div className="jobs-container">
      <div className="top">
        <div>
          <h3>Jobs</h3>
        </div>
        <div>
          <Link to={"/jobs/add"}>
            <Button className="button" size="small" variant="contained">
              Add job
            </Button>
          </Link>
        </div>
      </div>

      <JobsTable total={0} jobs={jobs} />
    </div>
  );
};
export { Jobs };
