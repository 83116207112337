import { ReactComponent as Logo } from "@assets/logo.svg";
import { Button } from "@mui/material";
import { useAuthStore } from "@store";
import "@styles/navbar.css";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { useEffect } from "react";
import { PiSignOut } from "react-icons/pi";
import { Link, useNavigate } from "react-router";

export const Navbar = (props: any) => {
  const checkLocation = (name: string) => {
    return window.location.pathname.includes(name)
      ? " navbar-button-focused"
      : "";
  };

  const navigate = useNavigate();
  const { auth, setAuth } = useAuthStore();

  const handleLogout = () => {
    if (window.confirm("Are you sure?")) {
      axios
        .post(`${process.env.REACT_APP_API_URL}/signout`, {
          withCredentials: true,
        })
        .finally(() => {
          setAuth({
            isAuth: false,
          });
          localStorage.clear();
          sessionStorage.clear();
          navigate("/signin");
        });
    }
  };

  useEffect(() => {
    const refreshToken = localStorage.getItem("refreshToken");
    if (refreshToken) {
      const decodedToken = jwtDecode<{ username: string; isAuth: boolean }>(
        refreshToken,
      );
      setAuth({
        ...auth,
        username: decodedToken.username,
        isAuth: true,
      });
    }
  }, []);

  return (
    <div className="navbar">
      <ul className=" navbar-nav m-0 p-0 d-flex align-items-center flex-row">
        <li className="bend-logo-container">
          <Link
            to={"/"}
            className="nav-link d-flex flex-row align-items-center"
          >
            <Logo className="bend-logo" />
          </Link>
        </li>
        <li className={checkLocation("clients")}>
          <Link to={"/clients"} className={"nav-link"}>
            Clients
          </Link>
        </li>
        <li className={checkLocation("jobs")}>
          <Link to={"/jobs"} className={"nav-link"}>
            Jobs
          </Link>
        </li>
        <li className={checkLocation("talents")}>
          <Link to={"/talents"} className={"nav-link"}>
            Talents
          </Link>
        </li>
      </ul>
      {auth?.isAuth ? (
        <>
          <Button
            onClick={handleLogout}
            variant="text"
            className="d-flex gap-2"
          >
            Sign out <PiSignOut size={"20px"} />
          </Button>
        </>
      ) : (
        <>
          {auth?.role === "admin" ? (
            <Button variant="text" className="mx-2">
              <Link to="/signup">Sign Up</Link>
            </Button>
          ) : (
            ""
          )}
          <Button variant="text">
            <Link to="/signin">Sign In</Link>
          </Button>
        </>
      )}
    </div>
  );
};
