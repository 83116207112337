import { Popover } from "@mui/material";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useTalentStore } from "@store";
import dayjs from "dayjs";
import { MDBCardText } from "mdb-react-ui-kit";
import React, { useState } from "react";

export const TalentDatePicker = ({
  addMode,
  dateFrom,
  dateTo,
  index,
  editIndex,
  ongoing,
  onChange,
}: any) => {
  let date = dateFrom || dateTo;
  const { state } = useTalentStore();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (state.status !== "edit") return;
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (ongoing) {
    return (
      <div
        className={`d-flex flex-row align-items-center ${editIndex === index || addMode ? "calendar" : ""}`}
      >
        Present
      </div>
    );
  }

  const getDateLabel = () => {
    const dateNull = dayjs(date).isSame(new Date("0000"));

    if (dateNull) {
      date = dayjs(Date.now());
      return "None";
    }
    return dayjs(date).format("MMM YYYY");
  };

  return (
    <div>
      <MDBCardText
        onClick={handleClick}
        className={`p-0 m-0 text-nowrap ${state.status === "edit" ? "talent-hoverable" : ""}`}
      >
        {getDateLabel()}
      </MDBCardText>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateCalendar
            views={["year", "month"]}
            openTo="year"
            value={dayjs(date)}
            onChange={onChange}
          />
        </LocalizationProvider>
      </Popover>
    </div>
  );
};
