import { JobStatus } from "@components/jobs";
import { Button } from "@mui/material";
import { useJobStore } from "@store";
import { useEffect } from "react";
import { FiExternalLink } from "react-icons/fi";
import { Link } from "react-router";

const JobHeader = (props: any) => {
  const { jobState: state, jobDispatch: dispatch } = useJobStore();

  const observer = new IntersectionObserver(
    ([e]) => e.target.toggleAttribute("stuck", e.intersectionRatio < 1),
    {
      threshold: [1],
    },
  );
  useEffect(() => {
    observer.observe(document.querySelector(".job-sticky")!);
  }, []);

  return (
    <div className="job-sticky">
      <div className="d-flex justify-content-between ">
        <div className="d-flex flex-row gap-4">
          <Button
            className="job-back-button "
            size="small"
            onClick={props.navigateBack}
          >
            <div className="d-flex align-items-center">{`← Back to ${
              props.clientId ? "client" : "jobs"
            }`}</div>
          </Button>
          {!props.addMode ? (
            <div className="align-self-center">
              <Link
                target="_blank"
                to={`//${process.env.REACT_APP_URL?.split(
                  "://",
                )[1]}/public/jobs/${state.job?.id}`}
              >
                <h4 className=" d-flex m-0 align-items-center gap-2">
                  {state.job?.name} <FiExternalLink />
                </h4>
              </Link>
            </div>
          ) : (
            ""
          )}
        </div>
        {!props.addMode ? (
          <>
            <div className="d-flex flex-row gap-4 job-header-right">
              <JobStatus submitChanges={props.submitChanges} />

              <div className="d-flex flex-row align-items-center gap-4">
                {state.status !== "edit" ? (
                  <Button
                    className="text-nowrap background-bend-primary-color"
                    variant="contained"
                    onClick={() =>
                      dispatch({ type: "SET_STATUS", payload: "edit" })
                    }
                  >
                    Edit job
                  </Button>
                ) : (
                  <div className="d-flex gap-2 flex-row">
                    <Button
                      className="text-nowrap background-bend-primary-color"
                      variant="contained"
                      onClick={() => props.formRef.current?.requestSubmit()}
                    >
                      Save
                    </Button>
                    <Button
                      className="text-nowrap background-bend-primary-color"
                      variant="contained"
                      onClick={() => {
                        dispatch({ type: "SET_STATUS", payload: "view" });
                        dispatch({
                          type: "SET_JOB",
                          payload: state.originalJob!,
                        });
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
export { JobHeader };
