import { TextLabel } from "@components/textlabel";
import { MenuItem, TextField } from "@mui/material";
import { useTalentStore } from "@store";
import { formatProjectResponsibilities, wrapLinksInAnchorTags } from "@utils";
import parse from "html-react-parser";

type TalentTextFieldProps = {
  label: string;
  field: string;
  nestedField: string;
  select?: boolean;
  link?: boolean;
  options?: any[];
  index: number;
  minRows?: number;
  list?: boolean;
  required?: boolean;
};

export const TalentNestedTextField = ({
  field,
  select,
  link,
  options,
  label,
  index,
  nestedField,
  minRows,
  list,
  required,
}: TalentTextFieldProps) => {
  const { state, dispatch } = useTalentStore();
  let fieldValue = state.talent?.[nestedField][index][field];
  const {
    register,
    formState: { errors },
  } = state.form!;

  const renderSelectMenu = () => {
    if (select) {
      const items = options?.map((option: any, index: number) => {
        return (
          <MenuItem className="text-capitalize" key={index} value={option}>
            {option}
          </MenuItem>
        );
      });
      return [
        <MenuItem
          className="text-capitalize"
          disabled
          hidden
          value={"null"}
        ></MenuItem>,
        ...(items || []),
      ];
    }
    return null;
  };

  if (!fieldValue && state.status !== "edit") return null;

  return (
    <div>
      <TextLabel>{label}</TextLabel>
      {state.status === "edit" ? (
        <TextField
          {...register(`${nestedField}.${index}.${field}`, {
            required,
          })}
          error={!!errors[`${nestedField}.${index}.${field}`]}
          helperText={
            errors[`${nestedField}.${index}.${field}`] ? "Required" : ""
          }
          minRows={minRows}
          multiline={!!minRows}
          className="w-100"
          select={select}
          size="small"
          variant="outlined"
          value={state.talent?.[nestedField][index][field] || ""}
          onChange={(event: any) => {
            dispatch({
              type: "SET_TALENT",
              payload: {
                ...state.talent,
                [nestedField]: state.talent?.[nestedField].map(
                  (item: any, i: number) => {
                    if (i === index) {
                      return { ...item, [field]: event.target.value };
                    }
                    return item;
                  }
                ),
              },
            });
          }}
        >
          {renderSelectMenu()}
        </TextField>
      ) : (
        <div className="py-1">
          {list
            ? formatProjectResponsibilities(fieldValue)
            : link
              ? parse(wrapLinksInAnchorTags(fieldValue))
              : fieldValue}
        </div>
      )}
    </div>
  );
};
