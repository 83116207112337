import { TextLabel } from "@components/textlabel";
import { Collapse, IconButton } from "@mui/material";
import { useTalentStore } from "@store";
import { stripHtml, wrapLinksInAnchorTags } from "@utils";
import parse from "html-react-parser";
import { useEffect, useRef, useState } from "react";
import { MdExpandMore } from "react-icons/md";
import ReactQuill from "react-quill-new";

type TalentTextFieldProps = {
  label: string;
  field: string;
  link?: boolean;
  required?: boolean;
  internal?: boolean;
  collapsable?: boolean;
};

const TalentRTE = ({
  field,
  link,
  label,
  required,
  internal,
  collapsable,
}: TalentTextFieldProps) => {
  const { state, dispatch } = useTalentStore();
  const fieldValue = state.talent?.[field];
  const {
    register,
    setValue,
    formState: { errors },
  } = state.form!;
  const refHeight = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  const [collapsedSize, setCollapsedSize] = useState(0);

  useEffect(() => {
    setCollapsedSize(
      refHeight.current?.offsetHeight! > 200
        ? 200
        : refHeight.current?.offsetHeight!
    );
  }, [refHeight.current?.offsetHeight]);

  useEffect(() => {
    register(field, { required: required });
  }, []);

  const onEditorStateChange = (value: string) => {
    setValue(field, stripHtml(value) || null);

    dispatch({
      type: "SET_TALENT",
      payload: {
        ...state.talent,
        [field]: value,
      },
    });
  };

  if (state.status !== "edit" && !stripHtml(fieldValue)) return null;

  return (
    <div className="w-100">
      <TextLabel internal={internal}>{label}</TextLabel>
      {state.status === "edit" ? (
        <div className="d-flex flex-column">
          <ReactQuill
            className={errors[field] && "error-input"}
            theme="snow"
            value={fieldValue || ""}
            onChange={onEditorStateChange}
          />
          {errors[field] && <p className="error">{"Enter valid content"}</p>}
        </div>
      ) : (
        <div className="py-1">
          {collapsable ? (
            <>
              <Collapse
                className={
                  refHeight.current?.offsetHeight! > 200 && !open
                    ? "talent-closed-collapse"
                    : ""
                }
                collapsedSize={collapsedSize}
                in={open}
              >
                <div ref={refHeight}>{parse(fieldValue)}</div>
              </Collapse>
              <>
                {" "}
                {refHeight.current?.offsetHeight! > 200 ? (
                  <div className="talent-collapse-button">
                    <IconButton
                      style={{
                        outline: "none",
                        boxShadow: "none",
                        transform: open ? "rotate(180deg)" : "",
                        top: open ? "5px" : "-10px",
                      }}
                      onClick={() => {
                        setOpen(!open);
                      }}
                    >
                      <MdExpandMore />
                    </IconButton>
                  </div>
                ) : (
                  ""
                )}
              </>
            </>
          ) : link ? (
            parse(wrapLinksInAnchorTags(fieldValue))
          ) : (
            parse(fieldValue)
          )}
        </div>
      )}
    </div>
  );
};

export { TalentRTE };
