import { LanguageFormType, SelectedLanguage } from "@interfaces";
import { Autocomplete, IconButton, MenuItem, TextField } from "@mui/material";
import { useTalentsStore, useTalentStore } from "@store";
import { IoRemoveCircleOutline } from "react-icons/io5";

export const TalentLanguagesForm = ({
  language,
  level,
  index,
  handleLanguageDelete,
}: LanguageFormType) => {
  const { languageLevels } = useTalentsStore();
  const { state, dispatch } = useTalentStore();
  const {
    register,
    formState: { errors },
  } = state.form!;

  return (
    <div>
      {state.status === "edit" ? (
        <div className="talent-lang-form">
          <IconButton
            size="small"
            className="p-0"
            onClick={(e) => handleLanguageDelete(e, index)}
          >
            <IoRemoveCircleOutline />
          </IconButton>
          <Autocomplete
            size="small"
            options={[
              "French",
              "Spanish",
              "German",
              "Italian",
              "Portuguese",
              "Russian",
              "Chinese",
              "Japanese",
              "Swedish",
              "Danish",
              "Norwegian",
              "Finnish",
              "Polish",
              "Romanian",
              "Czech",
              "Hungarian",
              "Bulgarian",
              "Greek",
              "Turkish",
              "Hebrew",
              "Armenian",
              "Azerbaijani",
              "Georgian",
              "Ukrainian",
              "Belarusian",
            ]}
            onChange={(event, newValue) => {
              dispatch({
                type: "SET_TALENT",
                payload: {
                  ...state.talent,
                  cv_languages: state.talent?.cv_languages?.map(
                    (lang: SelectedLanguage, i: number) =>
                      i === index
                        ? {
                            ...lang,
                            language: newValue,
                          }
                        : lang
                  ),
                },
              });
            }}
            value={language || ""}
            freeSolo
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="*Select language"
                {...register("language" + index, {
                  required: true,
                  value: language,
                })}
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                }}
                variant="outlined"
                error={
                  !!errors["language" + index] || !!errors["level" + index]
                }
                helperText={
                  errors["language" + index]
                    ? "Language is required"
                    : errors["level" + index]
                      ? "Level is required"
                      : ""
                }
              />
            )}
          />
          <TextField
            select
            size="small"
            value={level || ""}
            {...register("level" + index, {
              required: true,
              value: level,
            })}
            onChange={(event: any) => {
              dispatch({
                type: "SET_TALENT",
                payload: {
                  ...state.talent,
                  cv_languages: state.talent?.cv_languages?.map(
                    (lang: SelectedLanguage, i: number) =>
                      i === index
                        ? {
                            ...lang,
                            level: event.target.value,
                          }
                        : lang
                  ),
                },
              });
            }}
          >
            <MenuItem disabled value="">
              Select level
            </MenuItem>
            {languageLevels.map((level: string) => (
              <MenuItem key={level} value={level}>
                {level}
              </MenuItem>
            ))}
          </TextField>
        </div>
      ) : (
        <div className="d-flex flex-row gap-4 justify-content-between px-4">
          <div>{language}</div>
          <div>{level}</div>
        </div>
      )}
    </div>
  );
};
