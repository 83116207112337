import {
  TalentCalendarRange,
  TalentNestedTextField,
  TalentProgressToggle,
} from "@components/talents";
import { Button, IconButton } from "@mui/material";
import { useTalentStore } from "@store";
import {
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCol,
  MDBRow,
} from "mdb-react-ui-kit";
import { useState } from "react";
import { GoTrash } from "react-icons/go";

export const TalentEducation = (props: any) => {
  const [showAddButton, setShowAddButton] = useState<boolean>(false);
  const [editEducationIndex, setEditEducationIndex] = useState<number>(-1);
  const { state, dispatch } = useTalentStore();

  const deleteEducation = (index: number) => {
    if (window.confirm("Are you sure you want to delete this Education?")) {
      const changes = {
        cv_education: state.talent?.cv_education.filter(
          (educ: any, i: number) => i !== index
        )!,
      };
      dispatch({
        type: "SET_TALENT",
        payload: { ...state.talent, ...changes },
      });
    }
  };

  if (state.talent?.cv_education?.length === 0 && state.status !== "edit")
    return null;

  return (
    <>
      <MDBCard
        className="border-0 "
        onMouseEnter={() => setShowAddButton(true)}
        onMouseLeave={() => setShowAddButton(false)}
      >
        <MDBCardHeader>
          <MDBRow>
            <MDBCol className="d-flex flex-grow-1 justify-content-start">
              <div className="d-flex flex-row align-items-center gap-2">
                Education
                <div
                  style={{
                    display: "inline-block",
                    color: "orange",
                    fontSize: 12,
                    fontStyle: "italic",
                    fontWeight: 300,
                  }}
                >
                  public
                </div>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBCardHeader>
        <MDBCardBody className="p-0 px-3">
          {state.talent?.cv_education?.length
            ? state.talent?.cv_education?.map(
                (Education: any, index: number) => {
                  return (
                    <MDBCard
                      key={index}
                      className={
                        "project-card border-0 " + (index ? "border-top" : " ")
                      }
                    >
                      <MDBCardBody className="card-body-element p-2">
                        <div
                          className={
                            "delete" +
                            (state.status === "edit" ? "" : " d-none")
                          }
                        >
                          <IconButton onClick={(e) => deleteEducation(index)}>
                            <GoTrash color="#dc3546" />
                          </IconButton>
                        </div>
                        <div className=" talent-education-body-header">
                          <TalentNestedTextField
                            nestedField="cv_education"
                            field="name"
                            index={index}
                            label="Education"
                          />
                          <TalentNestedTextField
                            nestedField="cv_education"
                            field="institution"
                            index={index}
                            label="Institution"
                          />

                          <TalentCalendarRange
                            index={index}
                            dateObjects={state.talent?.cv_education}
                            objectType="cv_education"
                          />
                          <TalentProgressToggle
                            index={index}
                            nested_field="cv_education"
                          />
                        </div>
                      </MDBCardBody>
                    </MDBCard>
                  );
                }
              )
            : ""}
          {state.status === "edit" ? (
            <Button
              className="my-2"
              size="small"
              variant="outlined"
              style={{
                outline: "none",
                boxShadow: "none",
                borderRadius: 30,
              }}
              onClick={() => {
                dispatch({
                  type: "SET_TALENT",
                  payload: {
                    ...state.talent,
                    cv_education: [
                      ...state.talent?.cv_education,
                      {
                        name: "",
                        institution: "",
                        ongoing: false,
                        from: new Date("0000"),
                        to: new Date("0000"),
                      },
                    ],
                  },
                });
              }}
            >
              + Add Education
            </Button>
          ) : (
            ""
          )}
        </MDBCardBody>
      </MDBCard>
    </>
  );
};
