import { TalentLanguages, TalentTechnologies } from "@components/talents";
import { useTalentStore } from "@store";

export const TalentSkills = () => {
  const { state, dispatch } = useTalentStore();
  if (
    state.talent?.cv_core_technologies?.length === 0 &&
    state.talent?.cv_other_technologies?.length === 0 &&
    state.talent?.cv_languages?.length === 0 &&
    state.status !== "edit"
  )
    return null;
  return (
    <div className="d-flex flex-row justify-content-between talent-skills-cards">
      <TalentTechnologies />
      <TalentLanguages />
    </div>
  );
};
