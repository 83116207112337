import { ScoreCategory } from "@interfaces";

export const SCORE_WEIGHTS: Record<ScoreCategory, number> = {
  [ScoreCategory.CommunicationSkills]: 0.1,
  [ScoreCategory.ProblemSolving]: 0.1,
  [ScoreCategory.AdaptabilityAndFlexibility]: 0.15,
  [ScoreCategory.Interview]: 0.25,
  [ScoreCategory.Technical]: 0.3,
  [ScoreCategory.English]: 0.1,
};
