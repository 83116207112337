import { ClientProfile, Clients } from "@pages/clients";
import { Route, Routes } from "react-router";

const ClientsRouter = (props: any) => {
  return (
    <Routes>
      <Route path="/" element={<Clients />} />
      <Route path="/add" element={<ClientProfile />} />
      <Route path="/:id" element={<ClientProfile />} />
    </Routes>
  );
};

export { ClientsRouter };
