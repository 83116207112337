import { JobClientInput, JobRTE, JobTextField } from "@components/jobs";
import { useJobStore } from "@store";
import { MDBCard } from "mdb-react-ui-kit";
import { forwardRef } from "react";

const JobGeneralInfo = forwardRef((props: any, ref: any) => {
  const { jobState: state, jobDispatch: dispatch } = useJobStore();

  return (
    <MDBCard className="job-general-info ">
      <div className="   d-flex flex-column w-100 gap-4">
        <JobTextField field={["name"]} label="Job title" required />
        <div className="job-general-info-grid">
          <JobClientInput clientId={props.clientId} />
          <JobTextField
            field={["contract_type"]}
            label="Contract type"
            select
            options={state.context?.job_contract!}
          />

          <JobTextField field={["location"]} label="Location" />
          <JobTextField
            field={["work_location"]}
            label="Work location"
            select
            options={state.context?.job_work_location!}
          />
        </div>

        <div className="job-details">
          <JobRTE field={"description"} label="Description" />
          <JobRTE field={"requirements"} label="Requirements" />
          <JobRTE field={"benefits"} label="Benefits" />
          <JobRTE field={"internal_notes"} label="Internal notes" internal />
        </div>
      </div>
    </MDBCard>
  );
});
export { JobGeneralInfo };
