import React, { useEffect, useState } from "react";
import { MenuItem, TextField } from "@mui/material";
import { useTalentStore } from "@store";
import { TextLabel } from "@components/textlabel";

function SalaryRangeField() {
  const {
    state: { talent, form, status },
    dispatch,
  } = useTalentStore();
  const [salaryRange, setSalaryRange] = useState<string>("");
  const { register } = form!;

  useEffect(() => {
    setSalaryRange(
      `${talent?.salary?.from || ""}${talent?.salary?.to ? " - " : ""}${talent?.salary?.to || ""}`
    );
  }, []);

  if (status !== "edit" && !talent?.salary?.from) return null;

  const handleChange = (e: any) => {
    const { value } = e.target as HTMLInputElement;

    const [from, to] =
      value
        .match(/[\d\s-]+/g)
        ?.join()
        .split("-")
        .map((v: string) => v.trim() || "")! || [];

    setSalaryRange(value.match(/[\d\s-]+/g)?.join()! || "");

    dispatch({
      type: "SET_TALENT",
      payload: {
        ...talent,
        salary: { ...talent?.salary, from: from || "", to: to || "" },
      },
    });
  };

  const handleCurrencyChange = (e: any) => {
    const { value } = e.target;

    dispatch({
      type: "SET_TALENT",
      payload: {
        ...talent,
        salary: { ...talent?.salary, currency: value },
      },
    });
  };

  const formatSalaryRange = (salary: any) => {
    return `${salary.from || ""}${salary.to && salary.to !== salary.from ? " - " : ""}${salary.to && salary.to !== salary.from ? salary.to : ""} ${salary.currency || ""}`;
  };

  return (
    <div>
      <TextLabel>Salary Range (monthly)</TextLabel>
      {status === "edit" ? (
        <div className="d-flex gap-2">
          <TextField
            {...register("salary")}
            size="small"
            value={salaryRange}
            onChange={handleChange}
            fullWidth
            variant="outlined"
          ></TextField>
          <TextField
            className="w-25"
            {...register("salary_currency")}
            size="small"
            value={talent?.salary?.currency || "EUR"}
            onChange={handleCurrencyChange}
            select
            fullWidth
            variant="outlined"
          >
            <MenuItem key={"mdl"} value={"MDL"}>
              {"MDL"}
            </MenuItem>
            <MenuItem key={"eur"} value={"USD"}>
              {"USD"}
            </MenuItem>
            <MenuItem key={"usd"} value={"EUR"}>
              {"EUR"}
            </MenuItem>
          </TextField>
        </div>
      ) : (
        <div className="py-1">{formatSalaryRange(talent?.salary)}</div>
      )}
    </div>
  );
}

export default SalaryRangeField;
