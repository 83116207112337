export const wrapLinksInAnchorTags = (htmlString: string) => {
  if (!htmlString) return htmlString;
  const regex =
    /(\b((https?|ftp):\/\/|www\.)[\w-]+(\.[\w-]+)+([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?\b)\/?/gi;
  return htmlString.replace(regex, (url) => {
    const href = url.startsWith("http") ? url : `https://${url}`;
    return `<a href="${href}" target="_blank" rel="noopener noreferrer">${href}</a>`;
  });
};

export const serializeQueryParams = (query: any) => {
  return (
    "?" +
    Object.entries(query)
      .filter(([, value]) => {
        if (value || value === 0 || value === false) {
          return true;
        }
        return false;
      })
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          if (value.length)
            return `${key}=${encodeURIComponent(JSON.stringify(value))}`;
          else return null;
        }
        return `${key}=${encodeURIComponent(value as string)}`;
      })
      .join("&")
  );
};

export const stripHtml = (html: string) => {
  if (!html) return html;
  return html.replace(/<[^>]*>?/gm, "");
};
