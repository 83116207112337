import { JobProfile, Jobs } from "@pages/jobs";
import { Route, Routes } from "react-router";

const JobsRouter = (props: any) => {
  return (
    <Routes>
      <Route path="/" element={<Jobs />} />
      <Route path="/add" element={<JobProfile addMode={true} />} />
      <Route path="/:id" element={<JobProfile />} />
    </Routes>
  );
};

export { JobsRouter };
