import { NotFound } from "@pages/error";
import { JobPublic } from "@pages/jobs";
import { TalentPublic } from "@pages/talents";
import { Route, Routes } from "react-router";

const PublicRouter = (props: any) => {
  return (
    <Routes>
      <Route path="/talents/:id" element={<TalentPublic />} />
      <Route path="/jobs/:id" element={<JobPublic />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export { PublicRouter };
