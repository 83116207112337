import { ScoreCategory } from "@interfaces";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Tooltip } from "@mui/material";
import {
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCol,
  MDBRow,
} from "mdb-react-ui-kit";
import { TalentRating } from "./TalentRating";
import { TalentRatingTotal } from "./TalentRatingTotal";
import { TalentScorecardTooltip } from "./TalentScorecardTooltip";

export const TalentScorecard = (props: any) => {
  return (
    <>
      <MDBCard className="border-0 ">
        <MDBCardHeader>
          <MDBRow>
            <MDBCol className="d-flex flex-grow-1 justify-content-start">
              <div className="d-flex flex-row align-items-center gap-2">
                Candidate Scorecard
              </div>
              <Tooltip
                title={<TalentScorecardTooltip />}
                placement="bottom-start"
                leaveDelay={150}
                slotProps={{
                  tooltip: {
                    sx: {
                      color: "black",
                      backgroundColor: "lightgrey",
                      width: "32rem",
                      maxWidth: "100%",
                    },
                  },
                }}
              >
                <ErrorOutlineIcon
                  rotate={"180deg"}
                  className="ms-1"
                  opacity="30%"
                  fontSize="small"
                />
              </Tooltip>
            </MDBCol>
          </MDBRow>
        </MDBCardHeader>
        <MDBCardBody className={"p-2 px-3 d-flex flex-column gap-2"}>
          <span className="row">
            <p className="col text-wrap text-break"> </p>
            <div className="col-9 d-flex justify-content-evenly">
              <p>1 - Poor</p>
              <p>2 - Below Average</p>
              <p>3 - Average</p>
              <p>4 - Good</p>
              <p>5 - Excellent</p>
            </div>
          </span>

          <TalentRating
            label="Communication Skills"
            control={ScoreCategory.CommunicationSkills}
          />
          <TalentRating
            label="Problem-Solving"
            control={ScoreCategory.ProblemSolving}
          />
          <TalentRating
            label="Adaptability & Flexibility"
            control={ScoreCategory.AdaptabilityAndFlexibility}
          />
          <TalentRating
            label="Interview (HR + Cultural)"
            control={ScoreCategory.Interview}
          />
          <TalentRating label="Technical" control={ScoreCategory.Technical} />
          <TalentRating label="English" control={ScoreCategory.English} />
        </MDBCardBody>
      </MDBCard>
    </>
  );
};
