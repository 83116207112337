import { Button, TextField, Tooltip } from "@mui/material";
import { useTalentStore } from "@store";
import axios from "axios";
import {
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCol,
  MDBRow,
} from "mdb-react-ui-kit";
import { useRef, useState } from "react";
import { Form } from "reactstrap";

export const TalentAccessList = (props: any) => {
  const { state, dispatch } = useTalentStore();
  const [showCopiedIndex, setShowCopiedIndex] = useState<number>(-1);
  const formRef = useRef<any>(null);

  const { accessList, setAccessList } = props.accessList;

  const addKey = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const name = e.target[0].value;
    axios
      .post(`${process.env.REACT_APP_API_URL}/talents/access`, {
        name,
        talent: state.talent?.id,
      })
      .then((res) => {
        setAccessList([...accessList, res.data]);
      });
  };

  const handleClickCopy = (token: string, index: number) => {
    setShowCopiedIndex(index);
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_URL!}/public/talents/${
        state.talent?.external_id
      }?t=` + token
    );
  };

  const handleKeyStatus = (e: any, id: number) => {
    e.preventDefault();
    axios
      .post(`${process.env.REACT_APP_API_URL}/talents/access/${id}`)
      .then((res) => {
        setAccessList(
          accessList.map((access: any) => {
            if (access.id === id) {
              return res.data;
            }
            return access;
          })
        );
      });
  };

  return (
    <div>
      <MDBCard className="border-0 pb-3">
        <MDBCardHeader className=" font-weight-bold border-0">
          Create a public access token
        </MDBCardHeader>
        <MDBCardBody className="py-0">
          <div>
            <Form onSubmit={(e) => addKey(e)} innerRef={formRef}>
              <MDBRow className="flex-row justify-content-between align-items-center">
                <MDBCol>
                  <TextField
                    size="small"
                    className="w-100"
                    label="Access key name"
                    required
                  ></TextField>
                </MDBCol>
                <MDBCol className="text-nowrap d-flex m-0 flex-grow-0 justify-content-end">
                  <Button
                    type="button"
                    size="small"
                    variant="outlined"
                    onClick={(e) => {
                      formRef.current.requestSubmit();
                    }}
                  >
                    + Add access token
                  </Button>
                </MDBCol>
              </MDBRow>
            </Form>
          </div>
          {accessList.length ? (
            <>
              <MDBRow className="pt-2">
                <MDBCol md={2}>
                  <span className=" ml-2 text-primary">Name</span>
                </MDBCol>
                <MDBCol md={5}>
                  <span className="text-primary">Key</span>
                </MDBCol>
                <MDBCol md={1}>
                  <span className="text-primary">Views</span>
                </MDBCol>
                <MDBCol md={2}>
                  <span className="text-primary"> </span>
                </MDBCol>
              </MDBRow>
              <hr
                style={{
                  margin: "8px 0",
                  borderWidth: "2px",
                  backgroundColor: "black",
                }}
              />

              <div className="d-flex flex-column gap-0">
                {accessList.map((access: any, index: number) => {
                  return (
                    <div key={index}>
                      {" "}
                      {index ? <hr /> : ""}
                      <div className="talent-access-body">
                        <span>{access.name}</span>
                        <span>{access.token}</span>
                        <span>{access.views}</span>
                        <span
                          className="d-flex justify-content-center"
                          style={{ color: access.is_active ? "green" : "red" }}
                        >
                          {access.is_active ? "Active" : "Inactive"}
                        </span>
                        <div className="d-flex flex-row justify-content-end">
                          <Tooltip
                            leaveDelay={1500}
                            open={showCopiedIndex === index}
                            onClose={() => setShowCopiedIndex(-1)}
                            title="Copied!"
                            placement="bottom"
                          >
                            <Button
                              className=" align-items-center"
                              onClick={() =>
                                handleClickCopy(access.token, index)
                              }
                              variant="text"
                            >
                              copy
                            </Button>
                          </Tooltip>
                          <Button
                            onClick={(e: any) => {
                              handleKeyStatus(e, access?.id);
                            }}
                            variant="text"
                          >
                            {access.is_active ? "revoke" : "enable"}
                          </Button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            ""
          )}
        </MDBCardBody>
      </MDBCard>
    </div>
  );
};
