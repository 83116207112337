import { SignIn, SignUp } from "@pages/authentication";
import { NotFound } from "@pages/error";
import { Home } from "@pages/home";
import { Profile } from "@pages/profile";
import { ClientsRouter, TalentsRouter } from "@routers";
import { ClientStoreProvider, JobStoreProvider } from "@store";
import { Route, Routes } from "react-router";
import { JobsRouter } from "./JobsRouter";

const MainRouter = (props: any) => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="talents/*" element={<TalentsRouter />} />
      <Route element={<ClientStoreProvider />}>
        <Route path="clients/*" element={<ClientsRouter />} />
        <Route element={<JobStoreProvider />}>
          <Route path="jobs/*" element={<JobsRouter />} />
        </Route>
      </Route>
      <Route path="/signup" element={<SignUp />} />
      <Route path="/signin" element={<SignIn />} />
      <Route path="/profile/:username" element={<Profile />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export { MainRouter };
